
import { Options, Vue } from "vue-class-component";
import Header from "./components/Header.vue";
import Card from "./components/Card.vue";
import content from "./content.json";

@Options({
  data() {
    return {
      content: content,
    };
  },
  components: {
    Header,
    Card,
  },
})
export default class App extends Vue {}
