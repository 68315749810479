
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    cardHeader: String,
    cardBody: String,
    cardBodyImage: String,
  },
})
export default class Card extends Vue {
  cardHeader!: string;
  cardBody!: string;
  cardBodyImage!: string;
}
