import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b007c24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card_surround" }
const _hoisted_2 = { class: "card_header" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "card_body" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, [
      _createElementVNode("span", { innerHTML: _ctx.cardHeader }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "card_body_text",
        innerHTML: _ctx.cardBody
      }, null, 8, _hoisted_5),
      _createElementVNode("div", {
        class: "card_body_image",
        innerHTML: _ctx.cardBodyImage
      }, null, 8, _hoisted_6)
    ])
  ]))
}