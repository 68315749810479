
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    coTitle: String,
    coSubtitle: String,
  },
})
export default class Header extends Vue {
  msg!: string;
}
