import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cards" }
const _hoisted_2 = { class: "profile_img" }
const _hoisted_3 = { class: "cards text_cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      coTitle: _ctx.content.coTitle,
      coSubtitle: _ctx.content.coSubtitle
    }, null, 8, ["coTitle", "coSubtitle"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Card, {
          cardBodyImage: _ctx.content.cards.introduction.image
        }, null, 8, ["cardBodyImage"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Card, {
          cardBody: _ctx.content.cards.introduction.body,
          cardHeader: _ctx.content.cards.introduction.header
        }, null, 8, ["cardBody", "cardHeader"]),
        _createVNode(_component_Card, {
          cardBody: _ctx.content.cards.contact.body,
          cardHeader: _ctx.content.cards.contact.header
        }, null, 8, ["cardBody", "cardHeader"])
      ])
    ])
  ], 64))
}